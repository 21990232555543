import data from "./../data.json";

export function mockTrelloPowerUpConnector() {
  return {
    async board(...scope) {
      return data.board;
    },
    async cards(...scope) {
      return data.cards;
    },
    async lists(...scope) {
      return data.lists;
    },
    async member(...scope) {
      return data.member;
    },
    getRestApi() {
      return {
        async getToken() {
          return data.token;
        },
      };
    },
    arg(...scope) {
      return undefined;
      // return "5f478b2d50b5af0dd13e634c";
    },
    render(fn) {},
    closeModal(fn) {},
    async get(...path) {
      let value = data;
      let key;

      while ((key = path.shift())) {
        value = value[key];
      }

      return value;
    },
    async set(...path) {},
  };
}

export const TRELLO_CARD_FIELDS = [
  {
    id: "list",
    name: "List",
  },
  {
    id: "name",
    name: "Name",
  },
  {
    id: "desc",
    name: "Description",
  },
  {
    id: "start",
    name: "Start date",
  },
  {
    id: "due",
    name: "Due date",
  },
  {
    id: "address",
    name: "Address",
  },
  {
    id: "locationName",
    name: "Location Name",
  },
  {
    id: "coordinates",
    name: "Coordinates",
  },
  // {
  //   id: "members",
  //   name: "Members",
  // },
  // {
  //   id: "labels",
  //   name: "Labels",
  // },
];
