import React, { useContext, useMemo } from "react";
import { xlsx } from "./../../adapters";

import { MonoParagraphSmall, ParagraphMedium } from "baseui/typography";
import { FileUploader } from "baseui/file-uploader";

import { ImportContext } from "../../contexts/ImportContext";
import { TRELLO_CARD_FIELDS } from "../../utils/trello";
import { useStyletron } from "baseui";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";

export default function FileUploadStep() {
  const {
    setCurrentStep,
    setFields,
    setData,
    setMapping,
    setFileName,
    setDefaults,
    setOutputData,
    setListIdentifactionOption,
    fileHasHeader,
    setFileHasHeader,
    board,
  } = useContext(ImportContext);

  const [css] = useStyletron();

  const boardFields = useMemo(() => {
    if (board && board.customFields) {
      return [...TRELLO_CARD_FIELDS, ...board.customFields];
    } else {
      return TRELLO_CARD_FIELDS;
    }
  }, [board]);

  return (
    <div>
      <ParagraphMedium $style={{ marginBottom: "6px" }}>
        Use the following headers in your CSV or Excel file to get fields
        automatically mapped:
      </ParagraphMedium>
      <div
        className={css({
          backgroundColor: "#eee",
          padding: "4px",
          display: "inline-block",
          marginBottom: "20px",
          borderRadius: "4px",
        })}
      >
        <MonoParagraphSmall color="negative" $style={{ margin: 0 }}>
          "Name", "Description", "Due date"
        </MonoParagraphSmall>
      </div>
      <div
        className={css({
          backgroundColor: "#eee",
          padding: "4px",
          display: "inline-block",
          marginBottom: "20px",
          marginLeft: "6px",
          borderRadius: "4px",
        })}
      >
        <MonoParagraphSmall color="negative" $style={{ margin: 0 }}>
          "List name" or "List ID"
        </MonoParagraphSmall>
      </div>
      <FileUploader
        accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/plain, .xlsm"
        onDropAccepted={async (accepted) => {
          const [file] = accepted;

          setFileName(file.path);

          const { data, fields } = await xlsx.parse(file, fileHasHeader);

          setMapping({});
          setDefaults({});
          setOutputData([]);
          setFields(fields);
          setData(data);

          for (const cardField of boardFields) {
            if (fields.includes(cardField.name)) {
              setMapping((mapping) => ({
                ...mapping,
                [cardField.id]: cardField.name,
              }));
            }
          }

          if (fields.includes("List name")) {
            setMapping((mapping) => ({
              ...mapping,
              list: "List name",
            }));
          }

          if (fields.includes("List ID")) {
            setMapping((mapping) => ({
              ...mapping,
              list: "List ID",
            }));
            setListIdentifactionOption("id");
          }

          setCurrentStep(2);
        }}
      />
      <Checkbox
        overrides={{ Root: { style: { marginTop: "20px" } } }}
        checked={fileHasHeader}
        onChange={(e) => setFileHasHeader(e.target.checked)}
        labelPlacement={LABEL_PLACEMENT.right}
      >
        File has a header row
      </Checkbox>
    </div>
  );
}
