import React, { useContext } from "react";

import { useStyletron } from "baseui";
import { Radio, RadioGroup } from "baseui/radio";

import { ImportContext } from "../../contexts/ImportContext";
import { LabelMedium, LabelXSmall } from "baseui/typography";

export default function SettingsStep() {
  const {
    position,
    setPosition,
    listIdentifactionOption,
    setListIdentifactionOption,
  } = useContext(ImportContext);
  const [css, theme] = useStyletron();

  return (
    <>
      <div className={css({ marginBottom: "20px" })}>
        <LabelMedium
          $style={{
            marginTop: theme.sizing.scale300,
            marginBottom: theme.sizing.scale100,
          }}
        >
          Position of cards
        </LabelMedium>
        <LabelXSmall
          $style={{
            marginTop: 0,
            marginBottom: theme.sizing.scale300,
            color: theme.colors.contentSecondary,
            fontWeight: 400,
          }}
        >
          Define if imported cards should be added at the top or bottom of
          lists.
        </LabelXSmall>
        <RadioGroup
          value={position}
          onChange={(e) => setPosition(e.target.value)}
          align="horizontal"
        >
          <Radio value="top">Top</Radio>
          <Radio value="bottom">Bottom</Radio>
        </RadioGroup>
      </div>
      <div className={css({ marginBottom: "20px" })}>
        <LabelMedium
          $style={{
            marginTop: theme.sizing.scale300,
            marginBottom: theme.sizing.scale100,
          }}
        >
          List identification attribute
        </LabelMedium>
        <LabelXSmall
          $style={{
            marginTop: 0,
            marginBottom: theme.sizing.scale300,
            color: theme.colors.contentSecondary,
            fontWeight: 400,
          }}
        >
          Choose by which attribute lists will be identified.
        </LabelXSmall>
        <RadioGroup
          value={listIdentifactionOption}
          onChange={(e) => setListIdentifactionOption(e.target.value)}
          align="horizontal"
        >
          <Radio value="name">Name</Radio>
          <Radio value="id">Id</Radio>
        </RadioGroup>
      </div>
    </>
  );
}
