import React, { createContext, useEffect, useState } from "react";
import { useTrello } from "./TrelloContext";
import * as Sentry from "@sentry/react";

import * as Promise from "bluebird";
import { TRELLO_CARD_FIELDS } from "../utils/trello";

export const ImportContext = createContext();

export function ImportProvider(props) {
  const [fileHasHeader, setFileHasHeader] = useState(true);
  const [lists, setLists] = useState([]);
  const [fields, setFields] = useState([]);
  const [data, setData] = useState([]);
  const [outputData, setOutputData] = useState([]);
  const [mapping, setMapping] = useState({});
  const [defaults, setDefaults] = useState({});
  const [columns, setColumns] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [position, setPosition] = useState("bottom");
  const [fileName, setFileName] = useState("");
  const [listIdentifactionOption, setListIdentifactionOption] =
    useState("name");
  const [board, setBoard] = useState(null);
  const [processedCards, setProcessedCards] = useState(0);
  const [errors, setErrors] = useState([]);
  const [importHasFinished, setImportHasFinished] = useState(false);
  const $trello = useTrello();

  useEffect(() => {
    if ($trello) {
      async function fetchLists() {
        setLists(await $trello.lists("id", "name"));
      }
      async function fetchBoard() {
        setBoard(await $trello.board("customFields"));
      }

      fetchBoard();
      fetchLists();
    }
  }, [$trello]);

  function reset() {
    setCurrentStep(1);
    setProcessedCards(0);
    setErrors([]);
    setImportHasFinished(false);
  }

  async function importCards() {
    const token = await $trello.getRestApi().getToken();
    setCurrentStep(currentStep + 1);

    if (token) {
      await Promise.each(outputData, ({ id, data }) => {
        return new Promise(async (resolve, reject) => {
          const list = lists.find(
            (list) => list[listIdentifactionOption] === data.list
          );

          if (list) {
            data.idList = list.id;
          } else {
            setErrors((errors) => [
              ...errors,
              {
                id,
                data: {
                  ...data,
                  error: "No list found",
                },
              },
            ]);
            setProcessedCards((processedCards) => processedCards + 1);
            return resolve();
          }

          if (data.due) {
            data.due = data.due.toJSON();
          }

          if (data.start) {
            data.start = data.start.toJSON();
          }

          for (let field in data) {
            if (
              data[field] === undefined ||
              data[field] === null ||
              data[field] === ""
            ) {
              delete data[field];
            }
          }

          for (let field in data) {
            if (
              ![...TRELLO_CARD_FIELDS.map((x) => x.id), "idList"].includes(
                field
              ) &&
              board &&
              board.customFields
            ) {
              const customField = board.customFields.find(
                (customField) => customField.id === field
              );

              if (customField) {
                if (customField.type === "list") {
                  const customFieldOption = customField.options.find(
                    (option) => option.value.text === data[field]
                  );

                  if (!customFieldOption) {
                    setErrors((errors) => [
                      ...errors,
                      {
                        id,
                        data: {
                          ...data,
                          error: `No custom field option found for ${customField.name}`,
                        },
                      },
                    ]);
                    setProcessedCards((processedCards) => processedCards + 1);
                    return resolve();
                  }
                }
              }
            }
          }

          const params = new URLSearchParams({
            key: process.env.REACT_APP_API_KEY,
            token,
            pos: position,
            ...data,
          });

          try {
            let response = await fetch(
              `https://api.trello.com/1/cards?${params}`,
              {
                method: "post",
              }
            );

            const wasResponseOk = response.ok;

            response = await response.json();

            if (!wasResponseOk) {
              throw new Error(response.message);
            }

            if (response.id) {
              const customFieldSetParams = new URLSearchParams({
                key: process.env.REACT_APP_API_KEY,
                token,
              });

              for (let field in data) {
                if (
                  ![...TRELLO_CARD_FIELDS.map((x) => x.id), "idList"].includes(
                    field
                  ) &&
                  board &&
                  board.customFields
                ) {
                  const customField = board.customFields.find(
                    (customField) => customField.id === field
                  );

                  if (customField) {
                    if (customField.type === "list") {
                      const customFieldOption = customField.options.find(
                        (option) => option.value.text === data[field]
                      );

                      if (customFieldOption) {
                        await fetch(
                          `https://api.trello.com/1/card/${response.id}/customField/${customField.id}/item?${customFieldSetParams}`,
                          {
                            method: "put",
                            headers: { "content-type": "application/json" },
                            body: JSON.stringify({
                              idValue: customFieldOption.id,
                            }),
                          }
                        );
                      }
                    } else {
                      await fetch(
                        `https://api.trello.com/1/card/${response.id}/customField/${customField.id}/item?${customFieldSetParams}`,
                        {
                          method: "put",
                          headers: { "content-type": "application/json" },
                          body: JSON.stringify({
                            value: {
                              [customField.type === "checkbox"
                                ? "checked"
                                : customField.type]:
                                customField.type === "date"
                                  ? data[field].toJSON()
                                  : customField.type === "checkbox"
                                  ? getBooleanInString(data[field])
                                  : data[field],
                            },
                          }),
                        }
                      );
                    }
                  }
                }
              }
            }

            setProcessedCards((processedCards) => processedCards + 1);
            setTimeout(() => resolve(), 500);
          } catch (error) {
            error.requestParams = params;
            Sentry.captureException(error);

            setErrors((errors) => [
              ...errors,
              {
                id,
                data: {
                  ...data,
                  error: error.message,
                },
              },
            ]);
            setProcessedCards((processedCards) => processedCards + 1);
            setTimeout(() => resolve(), 500);
          }
        });
      });

      setImportHasFinished(true);
    }
  }

  return (
    <ImportContext.Provider
      value={{
        lists,
        setLists,
        fields,
        setFields,
        data,
        setData,
        outputData,
        setOutputData,
        mapping,
        setMapping,
        defaults,
        setDefaults,
        currentStep,
        setCurrentStep,
        position,
        setPosition,
        listIdentifactionOption,
        setListIdentifactionOption,
        processedCards,
        setProcessedCards,
        importHasFinished,
        setImportHasFinished,
        importCards,
        fileName,
        setFileName,
        errors,
        columns,
        setColumns,
        fileHasHeader,
        setFileHasHeader,
        board,
        setBoard,
        reset,
      }}
      {...props}
    />
  );
}

function getBooleanInString(value) {
  return value !== "false" && !!value ? "true" : "false";
}
