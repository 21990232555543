import React, { useMemo } from "react";

import { HeadingXSmall, LabelMedium, ParagraphMedium } from "baseui/typography";
import { styled } from "baseui";

const Root = styled("div", {
  // display: "flex"
});

const ProgressHeader = styled("div", {
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
});

const StepHead = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  // "::after": {
  //   content: "''",
  //   position: "absolute",
  //   left: "50%",
  //   width: "100%",
  //   height: "2px",
  //   backgroundColor: "#ddd",
  // },
});

const StepNumber = styled("div", ({ isActive, isCompleted, $theme }) => ({
  width: "36px",
  height: "36px",
  boxSizing: "border-box",
  borderBottomLeftRadius: "50%",
  borderBottomRightRadius: "50%",
  borderTopRightRadius: "50%",
  borderTopLeftRadius: "50%",
  backgroundColor: isCompleted
    ? $theme.colors.positive
    : isActive
    ? "#000"
    : "#ddd",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export function Step({
  title,
  index,
  onSelect,
  isActive,
  isCompleted,
  children,
}) {
  return (
    <StepHead>
      <StepNumber isActive={isActive} isCompleted={isCompleted}>
        <ParagraphMedium color={isActive || isCompleted ? "white" : "black"}>
          {index + 1}
        </ParagraphMedium>
      </StepNumber>

      <LabelMedium>{children}</LabelMedium>
    </StepHead>
  );
}

export function ProgressSteps({ children, currentStep }) {
  // const [activeKey, setActiveKey] = useState(1);

  const steps = useMemo(
    () =>
      React.Children.map(children, (child, index) => {
        if (!child) return;

        const { title, header, children } = child.props;

        return {
          title,
          header,
          children,
        };
      }),
    [children]
  );

  const activeStep = useMemo(() => steps[currentStep - 1], [
    currentStep,
    steps,
  ]);

  const stepsHeads = useMemo(
    () =>
      React.Children.map(children, (child, index) => {
        if (!child) return;

        const key = child.key || String(index);
        return React.cloneElement(child, {
          key,
          id: key,
          index,
          isCompleted: index + 1 < currentStep,
          isActive: index + 1 === currentStep,
          // onSelect: () => setActiveKey(key),
          children: child.props.title,
        });
      }),
    [children, currentStep]
  );

  return (
    <Root>
      <ProgressHeader>{stepsHeads}</ProgressHeader>
      <HeadingXSmall
        $style={{ fontSize: "26px", textAlign: "center", margin: "30px 0" }}
      >
        {activeStep.header}
      </HeadingXSmall>
      {activeStep.children}
    </Root>
  );
}
