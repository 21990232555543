import React from "react";

import { Paragraph4 } from "baseui/typography";
import { StyledLink } from "baseui/link";
import { Block } from "baseui/block";
import { ArrowRight } from "baseui/icon";

export function OfficialLinks() {
  return (
    <>
      <Block paddingTop="48px">
        <StyledLink href={process.env.REACT_APP_LP_URL} target="_blank">
          Official website <ArrowRight />
        </StyledLink>
      </Block>
      <Block paddingTop="12px">
        <StyledLink href={process.env.REACT_APP_TERMS_URL} target="_blank">
          Terms of Service <ArrowRight />
        </StyledLink>
      </Block>
      <Block paddingTop="12px">
        <StyledLink href={process.env.REACT_APP_PRIVACY_URL} target="_blank">
          Privacy Policy <ArrowRight />
        </StyledLink>
      </Block>
      <Block paddingTop="60px">
        <Paragraph4>Copyright © 2023 Mig</Paragraph4>
      </Block>
    </>
  );
}
