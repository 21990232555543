import React from "react";

import { useStyletron } from "baseui";

import { H4, Paragraph3 } from "baseui/typography";
import { Block } from "baseui/block";
import { Grid, Cell } from "baseui/layout-grid";
import { useTrello } from "../contexts/TrelloContext";
import { Button } from "baseui/button";

export default function AuthorizeView() {
  const [css, theme] = useStyletron();
  const $trello = useTrello();

  return (
    <div
      className={css({
        paddingLeft: theme.sizing.scale600,
        paddingRight: theme.sizing.scale600,
        paddingBottom: theme.sizing.scale600,
        paddingTop: theme.sizing.scale600,
      })}
    >
      <Grid gridMargins={0}>
        <Cell span={[6, 4]}>
          <H4 marginTop={0} marginBottom={0}>
            Authorization needed
          </H4>
        </Cell>
        <Cell span={[6, 4]}>
          <Block paddingTop="12px">
            <Paragraph3>
              Imported cards will be added via Trello API therefore we need you
              to authorize beforehand.
            </Paragraph3>

            <Button
              onClick={async () => {
                try {
                  await $trello.getRestApi().authorize({ scope: "read,write" });
                  $trello.closePopup();
                } catch (error) {}
              }}
            >
              Authorize
            </Button>
          </Block>
        </Cell>
      </Grid>
    </div>
  );
}
