import React, { useEffect, useState } from "react";

import { useStyletron } from "baseui";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from "baseui/modal";
import { Tag } from "baseui/tag";

import { ProgressSteps, Step } from "../components/ProgressSteps";
import { ImportContext, ImportProvider } from "../contexts/ImportContext";
import { useTrello } from "../contexts/TrelloContext";

import FieldMappingStep from "./steps/FieldMappingStep";
import FileUploadStep from "./steps/FileUploadStep";
import FinalStep from "./steps/FinalStep";
import ReviewStep from "./steps/ReviewStep";
import { Button } from "baseui/button";
import { ButtonGroup } from "baseui/button-group";
import SettingsStep from "./steps/SettingsStep";
import { toaster } from "baseui/toast";
import { InvalidLicenseBox } from "../components/InvalidLicenseBox";
import { useLicense } from "../contexts/LicenseContext";

export default function ImportView() {
  const [css, theme] = useStyletron();
  const $trello = useTrello();
  const { valid, expiresAt } = useLicense();

  const [isAuthorizationModalOpen, setIsAuthorizationModalOpen] =
    useState(false);
  const [isApiKeyChange, setIsApiKeyChange] = useState(false);

  useEffect(() => {
    if ($trello) {
      async function checkIfAuthorizationIsNeeded() {
        const token = await $trello.getRestApi().getToken();
        if (!token) {
          setIsAuthorizationModalOpen(true);
          return;
        }

        const params = new URLSearchParams({
          key: process.env.REACT_APP_API_KEY,
          token,
        });

        const response = await fetch(
          `https://api.trello.com/1/tokens/${token}?${params}`
        );

        if (!response.ok) {
          setIsApiKeyChange(true);
          setIsAuthorizationModalOpen(true);
          await $trello.getRestApi().clearToken();
          return;
        }
      }
      checkIfAuthorizationIsNeeded();
    }
  }, [$trello]);

  if (!valid || !expiresAt) {
    return <InvalidLicenseBox />;
  }

  return (
    <div
      className={css({
        paddingLeft: theme.sizing.scale600,
        paddingRight: theme.sizing.scale600,
        paddingBottom: "80px",
        paddingTop: theme.sizing.scale600,
      })}
    >
      <ImportProvider>
        <ImportContext.Consumer>
          {({
            currentStep,
            setCurrentStep,
            importHasFinished,
            importCards,
            mapping,
            defaults,
            data,
            outputData,
          }) => (
            <>
              <ProgressSteps currentStep={currentStep}>
                <Step title="File upload" header="Upload a source file">
                  <FileUploadStep />
                </Step>
                <Step
                  title="Field mapping"
                  header="Map card fields with imported data"
                >
                  <FieldMappingStep />
                </Step>
                <Step title="Settings" header="Change import settings">
                  <SettingsStep />
                </Step>
                <Step
                  title="Preview"
                  header="Review data that will be imported"
                >
                  <ReviewStep />
                </Step>
                <Step
                  title="Import"
                  header={
                    importHasFinished
                      ? "Import has finished"
                      : "The cards are now being imported"
                  }
                >
                  <FinalStep />
                </Step>
              </ProgressSteps>

              <div
                className={css({
                  width: "100%",
                  position: "fixed",
                  display: "flex",
                  justifyContent: "space-between",
                  boxSizing: "border-box",
                  bottom: 0,
                  left: 0,
                  backgroundColor: theme.colors.background,
                  boxShadow: theme.lighting.shadow400,
                  paddingTop: theme.sizing.scale400,
                  paddingRight: theme.sizing.scale600,
                  paddingBottom: theme.sizing.scale400,
                  paddingLeft: theme.sizing.scale600,
                })}
              >
                <div className={css({ width: "33%" })}>
                  {$trello &&
                    currentStep < 5 &&
                    (currentStep === 1 ? (
                      <Button
                        kind="minimal"
                        onClick={() => $trello.closeModal()}
                      >
                        Cancel
                      </Button>
                    ) : (
                      <Button
                        kind="minimal"
                        onClick={() => setCurrentStep(currentStep - 1)}
                      >
                        Prev
                      </Button>
                    ))}
                </div>
                {currentStep > 1 && currentStep < 5 && (
                  <div
                    className={css({
                      width: "33%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    })}
                  >
                    Cards being imported:
                    <Tag
                      closeable={false}
                      variant="solid"
                      kind={
                        (currentStep === 4 ? outputData.length : data.length) >
                        0
                          ? "positive"
                          : "negative"
                      }
                    >
                      {currentStep === 4 ? outputData.length : data.length}
                    </Tag>
                  </div>
                )}
                <div className={css({ width: "33%" })}>
                  {currentStep === 4 && (
                    <ButtonGroup
                      overrides={{
                        Root: {
                          style: {
                            justifyContent: "flex-end",
                          },
                        },
                      }}
                    >
                      <Button
                        kind="primary"
                        disabled={outputData.length === 0}
                        onClick={importCards}
                        overrides={{
                          BaseButton: {
                            style: {
                              backgroundColor: theme.colors.accent,
                              color: theme.colors.contentInversePrimary,
                              ":hover": {
                                backgroundColor: theme.colors.accent500,
                              },
                            },
                          },
                        }}
                      >
                        Import
                      </Button>
                    </ButtonGroup>
                  )}

                  {currentStep > 1 && currentStep < 4 && (
                    <ButtonGroup
                      overrides={{
                        Root: {
                          style: {
                            justifyContent: "flex-end",
                          },
                        },
                      }}
                    >
                      <Button
                        kind="primary"
                        onClick={() => {
                          if (
                            !(
                              (mapping["list"] || defaults["list"]) &&
                              (mapping["name"] || defaults["name"])
                            )
                          ) {
                            toaster.negative(
                              <>
                                Select source field or default value for "List"
                                and "Name" card fields.
                              </>,
                              { autoHideDuration: 5000 }
                            );
                            return;
                          }

                          setCurrentStep(currentStep + 1);
                        }}
                        overrides={{
                          BaseButton: {
                            style: {
                              backgroundColor: theme.colors.accent,
                              color: theme.colors.contentInversePrimary,
                              ":hover": {
                                backgroundColor: theme.colors.accent500,
                              },
                            },
                          },
                        }}
                      >
                        Next
                      </Button>
                    </ButtonGroup>
                  )}
                </div>
              </div>
            </>
          )}
        </ImportContext.Consumer>
      </ImportProvider>

      <Modal
        onClose={() => setIsAuthorizationModalOpen(false)}
        isOpen={isAuthorizationModalOpen}
        overrides={{ Close: { style: { display: "none" } } }}
        animate
        autoFocus
      >
        <ModalHeader>Authorization needed</ModalHeader>
        <ModalBody>
          {isApiKeyChange
            ? "We've recently received a new API key. Sorry for inconveniece. Please authorize to continue."
            : "Imported cards will be added via Trello API therefore we need you to authorize beforehand."}
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={async () => {
              try {
                await $trello.getRestApi().authorize({ scope: "read,write" });
                setIsAuthorizationModalOpen(false);
              } catch (error) {}
            }}
          >
            Authorize
          </ModalButton>
        </ModalFooter>
      </Modal>
    </div>
  );
}
