import React, { useContext, useMemo } from "react";

import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";

import { ImportContext } from "../../contexts/ImportContext";
import { TRELLO_CARD_FIELDS } from "../../utils/trello";
import { Select } from "baseui/select";
import { Input } from "baseui/input";
import { Checkbox } from "baseui/checkbox";
import { DatePicker } from "baseui/datepicker";
import { TimePicker } from "baseui/timepicker";
import { useStyletron } from "baseui";

export default function FieldMappingStep() {
  const {
    lists,
    fields,
    mapping,
    setMapping,
    defaults,
    setDefaults,
    board,
  } = useContext(ImportContext);
  const [css] = useStyletron();

  const data = useMemo(() => {
    if (board && board.customFields) {
      return [...TRELLO_CARD_FIELDS, ...board.customFields];
    } else {
      return TRELLO_CARD_FIELDS;
    }
  }, [board]);

  return (
    <TableBuilder data={data}>
      <TableBuilderColumn header="Card field">
        {(row) => row.name}
      </TableBuilderColumn>
      <TableBuilderColumn header="Source">
        {(row) => (
          <>
            <Select
              options={fields.map((field) => ({
                label: field,
                id: field,
              }))}
              size="mini"
              value={mapping[row.id] && [{ id: mapping[row.id] }]}
              placeholder="Select"
              onChange={(params) =>
                setMapping((mapping) => ({
                  ...mapping,
                  [row.id]:
                    params.value.length > 0 ? params.value[0].id : undefined,
                }))
              }
            />
          </>
        )}
      </TableBuilderColumn>
      <TableBuilderColumn header="Default value">
        {(row) => (
          <>
            {row.type === "list" ? (
              <Select
                value={defaults[row.id] || []}
                options={row.options}
                getOptionLabel={({ option }) => option.value.text}
                getValueLabel={({ option }) => option.value.text}
                size="mini"
                onChange={({ value }) =>
                  setDefaults({
                    ...defaults,
                    [row.id]: value,
                  })
                }
              />
            ) : row.id === "list" ? (
              <Select
                value={defaults[row.id] || []}
                options={lists}
                labelKey="name"
                size="mini"
                onChange={({ value }) =>
                  setDefaults({
                    ...defaults,
                    [row.id]: value,
                  })
                }
              />
            ) : row.id === "due" ||
              row.id === "start" ||
              row.type === "date" ? (
              <div className={css({ display: "flex" })}>
                <DatePicker
                  clearable
                  size="mini"
                  value={defaults[row.id] || null}
                  onChange={({ date }) =>
                    setDefaults({
                      ...defaults,
                      [row.id]: date,
                    })
                  }
                />

                {defaults[row.id] && row.id !== "start" && (
                  <TimePicker
                    size="mini"
                    value={defaults[row.id] || ""}
                    onChange={(date) =>
                      setDefaults({
                        ...defaults,
                        [row.id]: date,
                      })
                    }
                  />
                )}
              </div>
            ) : row.type === "checkbox" ? (
              <Checkbox
                checked={defaults[row.id] === "true"}
                onChange={({ target }) =>
                  setDefaults({
                    ...defaults,
                    [row.id]: target.checked.toString(),
                  })
                }
              />
            ) : (
              <Input
                value={defaults[row.id] || ""}
                size="mini"
                type={row.type === "number" ? "number" : "text"}
                onChange={(e) =>
                  setDefaults({
                    ...defaults,
                    [row.id]: e.target.value,
                  })
                }
              />
            )}
          </>
        )}
      </TableBuilderColumn>
    </TableBuilder>
  );
}
