import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider, LocaleProvider } from "baseui";
import locale from "baseui/locale/en_US";
import { ToasterContainer } from "baseui/toast";
import { TrelloProvider } from "./contexts/TrelloContext";
import ImportView from "./containers/ImportView";
import AuthorizeView from "./containers/AuthorizeView";
import { LicenseProvider } from "./contexts/LicenseContext";

const engine = new Styletron();

const localeOverride = {
  fileuploader: {
    ...locale.fileuploader,
    dropFilesToUpload:
      "Drop a file (CSV or Excel) here to start an import process",
  },
};

export default function App() {
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <LocaleProvider locale={localeOverride}>
          <ToasterContainer>
            <TrelloProvider>
              <LicenseProvider>
                <Router>
                  <Switch>
                    <Route path="/import">
                      <ImportView />
                    </Route>
                    <Route path="/authorize">
                      <AuthorizeView />
                    </Route>
                  </Switch>
                </Router>
              </LicenseProvider>
            </TrelloProvider>
          </ToasterContainer>
        </LocaleProvider>
      </BaseProvider>
    </StyletronProvider>
  );
}
