import React, { useContext, useState } from "react";

import { styled, useStyletron } from "baseui";
import { ProgressBar } from "baseui/progress-bar";

import { ImportContext } from "../../contexts/ImportContext";
import { Button } from "baseui/button";
import { useTrello } from "../../contexts/TrelloContext";
import { ParagraphMedium } from "baseui/typography";
import { Tag } from "baseui/tag";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import {
  Unstable_StatefulDataTable as DataTable,
  StringColumn,
} from "baseui/data-table";

const Error = styled("span", ({ $theme }) => ({
  color: $theme.colors.negative,
}));

export default function FinalStep() {
  const {
    outputData,
    importHasFinished,
    processedCards,
    fileName,
    errors,
    columns,
    reset,
  } = useContext(ImportContext);
  const [css, theme] = useStyletron();
  const $trello = useTrello();

  const [isErrorsModalOpen, setIsErrorsModalOpen] = useState(false);

  return (
    <div>
      <div className={css({ textAlign: "center", marginTop: "100px" })}>
        <ParagraphMedium>
          Source file:{" "}
          <Tag
            overrides={{
              Text: { style: { maxWidth: "auto" } },
            }}
            closeable={false}
          >
            {fileName}
          </Tag>
        </ParagraphMedium>
        <ProgressBar
          value={(processedCards / outputData.length) * 100}
          showLabel
          overrides={{
            BarProgress: {
              style: {
                ...(importHasFinished && {
                  backgroundColor: theme.colors.positive,
                }),
              },
            },
          }}
          getProgressLabel={() =>
            `${processedCards} of ${outputData.length} cards processed`
          }
        />
        {errors.length > 0 && (
          <ParagraphMedium $as="div" $style={{ marginTop: "20px" }}>
            Failed importing
            <Tag kind="negative" closeable={false}>
              {errors.length}
            </Tag>
            card(s){" "}
            <Button
              size="mini"
              kind="secondary"
              onClick={() => setIsErrorsModalOpen(true)}
            >
              See details
            </Button>
          </ParagraphMedium>
        )}
        {importHasFinished && (
          <div className={css({ marginTop: "40px" })}>
            {$trello && (
              <Button kind="secondary" onClick={() => $trello.closeModal()}>
                Close
              </Button>
            )}
            <ParagraphMedium
              $style={{ display: "inline-block", padding: "0 10px" }}
            >
              or
            </ParagraphMedium>
            <Button onClick={reset}>Import another file</Button>
          </div>
        )}
        <Modal
          onClose={() => setIsErrorsModalOpen(false)}
          isOpen={isErrorsModalOpen}
          animate
          size="full"
        >
          <ModalHeader>Errors</ModalHeader>
          <ModalBody>
            <div className={css({ height: "400px" })}>
              <DataTable
                filterable={false}
                searchable={false}
                columns={[
                  ...columns,
                  StringColumn({
                    title: "Error",
                    maxWidth: 140,
                    mapDataToValue: (data) => (
                      <Error title={data.error}>{data.error}</Error>
                    ),
                    sortable: false,
                    lineClamp: 2,
                  }),
                ]}
                rows={errors}
              />
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </div>
    </div>
  );
}
