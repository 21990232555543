import React, { createContext, useContext, useState, useEffect } from "react";
import { mockTrelloPowerUpConnector } from "../utils/trello";
import { Loading } from "../components/loading";

export const TrelloContext = createContext();
function useForceUpdate() {
  const [, setValue] = useState(0);
  return () => setValue((value) => ++value);
}

export function TrelloProvider(props) {
  const [$trello, setTrello] = useState(null);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      setTrello(mockTrelloPowerUpConnector());
    } else {
      if (window.TrelloPowerUp) {
        const t = window.TrelloPowerUp.iframe({
          appKey: process.env.REACT_APP_API_KEY,
          appName: process.env.REACT_APP_NAME,
        });

        t.render(forceUpdate);

        setTrello(t);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !$trello ? (
    <Loading />
  ) : (
    <TrelloContext.Provider value={$trello} {...props} />
  );
}

export function useTrello() {
  return useContext(TrelloContext);
}
