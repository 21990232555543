import XLSX from "xlsx";
import csv from "./csv";

export default {
  parse(file, hasHeader = true) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async function (e) {
        const data = [...new Uint8Array(e.target.result)]
          .map((x) => String.fromCharCode(x))
          .join("");
        const workbook = XLSX.read(data, { type: "binary", codepage: 65001 });

        const contents = XLSX.utils.sheet_to_csv(
          Object.values(workbook.Sheets)[0]
        );
        const [firstLine] = contents.split("\n");

        const headersCount = (firstLine.match(/,/g) || []).length + 1;

        const substituteHeaders = [...Array(headersCount).keys()].map(
          (index) => `Column ${index + 1}`
        );

        resolve(
          await csv.parse(
            (!hasHeader ? substituteHeaders.join(",") + "\n" : "") + contents
          )
        );
      };

      reader.readAsArrayBuffer(file);
    });
  },
};
