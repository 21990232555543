import Papa from "papaparse";

export default {
  parse(file) {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        complete({ data, meta }) {
          resolve({
            fields: meta.fields,
            data: data
              // Exclude empty rows
              .filter(
                (row) =>
                  !Object.values(row).reduce(
                    (result, value) => result && !value,
                    true
                  )
              ),
          });
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  },
};
