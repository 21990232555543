import React, { useContext, useEffect, useMemo } from "react";

import { useStyletron } from "baseui";
import {
  Unstable_StatefulDataTable as DataTable,
  StringColumn,
} from "baseui/data-table";
import { DeleteAlt } from "baseui/icon";

import { ImportContext } from "../../contexts/ImportContext";
import { TRELLO_CARD_FIELDS } from "../../utils/trello";

import dayjs from "dayjs";

export default function ReviewStep() {
  const {
    data,
    outputData,
    setOutputData,
    defaults,
    mapping,
    listIdentifactionOption,
    setColumns,
    board,
  } = useContext(ImportContext);
  const [css] = useStyletron();

  const fields = useMemo(() => {
    if (board && board.customFields) {
      return [...TRELLO_CARD_FIELDS, ...board.customFields];
    } else {
      return TRELLO_CARD_FIELDS;
    }
  }, [board]);

  const columns = useMemo(
    () =>
      fields
        .filter(
          ({ id }) =>
            mapping[id] ||
            (defaults[id] && !Array.isArray(defaults[id])) ||
            (defaults[id] &&
              Array.isArray(defaults[id]) &&
              defaults[id].length > 0)
        )
        .map(({ id, name }) =>
          StringColumn({
            title: name,
            maxWidth: 140,
            mapDataToValue: (data) => data[id] && data[id].toString(),
            sortable: false,
          })
        ),
    [fields, mapping, defaults]
  );

  useEffect(() => {
    setColumns(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  useEffect(() => {
    setOutputData(
      data.map((row, id) =>
        fields
          .filter(
            ({ id }) =>
              mapping[id] ||
              (defaults[id] && !Array.isArray(defaults[id])) ||
              (defaults[id] &&
                Array.isArray(defaults[id]) &&
                defaults[id].length > 0)
          )
          .reduce(
            ({ data, ...rest }, { id, type }) => ({
              ...rest,
              data: {
                ...data,
                [id]:
                  (id === "due" || id === "start" || type === "date"
                    ? row[mapping[id]] && dayjs(row[mapping[id]])
                    : row[mapping[id]]) ||
                  (id === "list"
                    ? defaults[id]
                      ? defaults[id][0][listIdentifactionOption]
                      : null
                    : id === "due" || id === "start" || type === "date"
                    ? defaults[id] && dayjs(defaults[id])
                    : type === "list"
                    ? defaults[id] && defaults[id][0] && defaults[id][0].value
                      ? defaults[id][0].value.text
                      : null
                    : defaults[id]),
              },
            }),
            {
              id,
              data: {},
            }
          )
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  function removeRows(ids) {
    const nextRows = outputData.filter((row) => !ids.includes(row.id));
    setOutputData(nextRows);
  }

  function removeRow(id) {
    removeRows([id]);
  }

  const rowActions = [
    {
      label: "Remove",
      onClick: ({ row }) => removeRow(row.id),
      renderIcon: ({ size }) => <DeleteAlt size={size} />,
    },
  ];

  const batchActions = [
    {
      label: "Remove selected",
      onClick: ({ selection, clearSelection }) => {
        removeRows(selection.map((r) => r.id));
        clearSelection();
      },
    },
  ];

  return (
    <>
      <div className={css({ height: "300px" })}>
        <DataTable
          filterable={false}
          searchable={false}
          columns={columns}
          rowActions={rowActions}
          batchActions={batchActions}
          rows={outputData}
          emptyMessage="No rows found."
        />
      </div>
    </>
  );
}
