import React from "react";

import { Spinner } from "baseui/spinner";
import { Block } from "baseui/block";

export function Loading() {
  return (
    <Block align="center" paddingTop="100px">
      <Spinner />
    </Block>
  )
}