import React, { createContext, useContext, useState, useEffect } from "react";
import { useTrello } from "./TrelloContext";
import { toaster } from "baseui/toast";
import { Loading } from "../components/loading";

export const LicenseContext = createContext();

export function LicenseProvider(props) {
  const $trello = useTrello();

  const [isLoading, setIsLoading] = useState(true);
  const [valid, setValid] = useState(true);
  const [expiresAt, setExpiresAt] = useState(0);

  async function authorize() {
    const { id: trelloMemberId } = await $trello.member("all");
    const lastAuthorizedAt = await $trello.get(
      "member",
      "private",
      "lastAuthorizedAt"
    );

    if (expiresAt < Date.now()) {
      setValid(false);
      setExpiresAt(0);
    } else if (lastAuthorizedAt !== new Date().toISOString().split("T")[0]) {
      let response = await fetch(
        `${process.env.REACT_APP_LICENSE_MANAGER_URL}/api/authorize/`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            trelloMemberId,
          }),
        }
      );

      if (response.ok) {
        response = await response.json();

        setValid(response.valid);

        if (response.valid) {
          setExpiresAt(response.expiresAt);
          await $trello.set(
            "member",
            "private",
            "licenseExpiresAt",
            response.expiresAt
          );
          await $trello.set(
            "member",
            "private",
            "lastAuthorizedAt",
            new Date().toISOString().split("T")[0]
          );
        } else {
          setExpiresAt(null);
          await $trello.set("member", "private", "licenseExpiresAt", null);
          await $trello.set("member", "private", "lastAuthorizedAt", null);
        }
      } else {
        setValid(false);
      }
    }
  }

  async function validate(licenseKey) {
    const member = await $trello.member("all");
    const { id, fullName } = member;

    let response = await fetch(
      `${process.env.REACT_APP_LICENSE_MANAGER_URL}/api/validate/`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          licenseKey,
          trelloMemberId: id,
          trelloMemberFullName: fullName,
        }),
      }
    );

    if (response.ok) {
      response = await response.json();

      setValid(response.valid);

      if (response.valid) {
        setExpiresAt(response.expiresAt);
        await $trello.set(
          "member",
          "private",
          "licenseExpiresAt",
          response.expiresAt
        );
        await $trello.set(
          "member",
          "private",
          "lastAuthorizedAt",
          new Date().toISOString().split("T")[0]
        );

        toaster.positive("License validated successfully.");
      }
    } else {
      setValid(false);
    }
  }

  useEffect(() => {
    (async () => {
      setExpiresAt(await $trello.get("member", "private", "licenseExpiresAt"));
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading && expiresAt) {
      (async () => {
        await authorize();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <LicenseContext.Provider
      value={{ valid, expiresAt, validate }}
      {...props}
    />
  );
}

export function useLicense() {
  return useContext(LicenseContext);
}
